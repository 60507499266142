<template>
    <div :class="(init) ? 'd-block white--text' : 'd-block'">
        <v-row no-gutters class="mt-1 mt-sm-2">
            <v-col cols="12">
                <v-divider :dark="init" class="mt-1 mb-1 mb-sm-3" />
            </v-col>
            <template v-if="$vuetify.breakpoint.smAndDown">
                <v-col cols="6" md="3" lg="3" class="pl-10">
                    <v-checkbox color="primary" :dark="init" :disabled="item.monday && lastRemainingDay()" v-model="item.monday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Mondays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.tuesday && lastRemainingDay()" v-model="item.tuesday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Tuesdays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.wednesday && lastRemainingDay()" v-model="item.wednesday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Wednesdays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.thursday && lastRemainingDay()" v-model="item.thursday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Thursdays" hide-details />
                </v-col>
                <v-col cols="6" md="3" lg="3" class="pl-10">
                    <v-checkbox color="primary" :dark="init" :disabled="item.friday && lastRemainingDay()" v-model="item.friday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Fridays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.saturday && lastRemainingDay()" v-model="item.saturday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Saturdays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.sunday && lastRemainingDay()" v-model="item.sunday" :false-value="false" :true-value="true" class="my-0 font-weight-medium" label="Sundays" hide-details />
                </v-col>
            </template>
            <template v-else>
                <v-col cols="4" md="3" lg="3" class="pl-sm-5 pl-md-0 pl-lg-5" offset-md="2" offset-lg="2">
                    <v-checkbox color="primary" :dark="init" :disabled="item.monday && lastRemainingDay()" v-model="item.monday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Mondays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.tuesday && lastRemainingDay()" v-model="item.tuesday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Tuesdays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.wednesday && lastRemainingDay()" v-model="item.wednesday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Wednesdays" hide-details />
                </v-col>
                <v-col cols="4" md="3" lg="3" class="pl-sm-5 pl-md-0 pl-lg-5">
                    <v-checkbox color="primary" :dark="init" :disabled="item.thursday && lastRemainingDay()" v-model="item.thursday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Thursdays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.friday && lastRemainingDay()" v-model="item.friday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Fridays" hide-details />
                </v-col>
                <v-col cols="4" md="3" lg="3" class="pl-sm-5 pl-md-0 pl-lg-5">
                    <v-checkbox color="primary" :dark="init" :disabled="item.saturday && lastRemainingDay()" v-model="item.saturday" :false-value="false" :true-value="true" :value="true" class="my-0 font-weight-medium" label="Saturdays" hide-details />
                    <v-checkbox color="primary" :dark="init" :disabled="item.sunday && lastRemainingDay()" v-model="item.sunday" :false-value="false" :true-value="true" class="my-0 font-weight-medium" label="Sundays" hide-details />
                </v-col>
            </template>
        </v-row>
    </div>
</template>
<script>

import { mapState } from 'vuex'

export default {

  name: 'CheckinDaysModal',

    props: {
        item: {
            required: true,
            type: Object
        },
        init: {
            required: false,
            type: Boolean
        }
    },

    computed: {
        ...mapState(['user'])
    },
    methods: {
        lastRemainingDay(){
            let checked = 0;
            let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            for(let i in days){
                if(this.item[days[i]]){
                    ++checked;
                }
            }
            return checked == 1;
        },
        save(){
            this.$eventHub.$emit('refreshUser');
            this.$emit('saved', true);
            this.close();
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
        item(){
            this.$emit('updated', this.item);
        }
    }
}
</script>
